import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Textarea from 'react-expanding-textarea';
import { isValidPhoneNumber } from '../../../lib/utils';

export interface ContactFormValues {
  fromEmail: string;
  fromName: string;
  fromPhone: string | null;
  messageBody: string;
  agreesToTerms: boolean;
}

const initialFormValues: ContactFormValues = {
  fromEmail: '',
  fromName: '',
  fromPhone: null,
  messageBody: '',
  agreesToTerms: false,
};

const validationSchema = yup.object({
  fromEmail: yup.string().email(`Please enter a valid email address`).required(`Please enter your email address`),
  fromName: yup.string().required(`Please enter your name`),
  fromPhone: yup
    .string()
    .optional()
    .nullable()
    .test('is_valid_phone', `Please enter a valid phone number`, isValidPhoneNumber),
  messageBody: yup.string().required(`Please enter a message`).max(600, `Message must be less than 600 characters`),
  agreesToTerms: yup.bool().oneOf([true], `You must agree to share your contact info`).required(),
});

export interface ContactFormProps {
  doSubmit(values: ContactFormValues): Promise<void>;
}

export default function ContactForm({ doSubmit }: ContactFormProps) {
  return (
    <Formik<ContactFormValues>
      validationSchema={validationSchema}
      initialValues={initialFormValues}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        try {
          await doSubmit(values);
        } catch (err) {
          setStatus({ errorMessage: (err as any).message });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, status, values, handleChange, handleBlur }) => (
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <div className="form-field">
              <Field type="text" name="fromEmail" placeholder="Your email" />
              <ErrorMessage name="fromEmail" component="div" className="form-field__error" />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <Field type="text" name="fromName" placeholder="Your name" />
              <ErrorMessage name="fromName" component="div" className="form-field__error" />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <Field type="text" name="fromPhone" placeholder="Your phone number" />
              <ErrorMessage name="fromPhone" component="div" className="form-field__error" />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <div className="textarea-container">
                <Textarea
                  name="messageBody"
                  placeholder="Send a message to the owner. Please include your contact information so they can contact you."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.messageBody}
                />
              </div>
              <ErrorMessage name="messageBody" component="div" className="form-field__error" />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label>
                <Field type="checkbox" name="agreesToTerms" />
                By sending this message I agree to share my email address with the recipient.
              </label>
              <ErrorMessage name="agreesToTerms" component="div" className="form-field__error" />
            </div>
          </div>

          <div className="form-group form-group--action">
            <div className="form-field">
              <button type="submit" disabled={isSubmitting} className="btn-yellow">
                Send Message
              </button>
              {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
