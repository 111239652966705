import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';
import Form from '../../../components/form/Form';
import SingleTextFieldFormGroup from '../../../components/form/SingleTextFieldFormGroup';
import StateSelectField from '../../../components/form/StateSelectField';
import { isValidPhoneNumber, saneEmail } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import styles from './ContactInfoForm.module.scss';
import Button from '../../../components/Button/Button';
import { useContext, useState } from 'react';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import { getFiGrowthBook } from '../../lib/growthbook';

export interface ContactInfoFormValues {
  firstName: string;
  lastName: string | null | undefined;
  secondaryFirstName: string | null | undefined;
  secondaryLastName: string | null | undefined;
  primaryEmail: string;
  secondaryEmail: string | null | undefined;
  primaryPhone: string | null | undefined;
  secondaryPhone: string | null | undefined;
  line1: string | null | undefined;
  line2: string | null | undefined;
  city: string | null | undefined;
  zipcode: string | null | undefined;
  state: string | null | undefined;
}

export function formValuesFromGql(gql: gqlTypes.contactInfo) {
  const firstName = gql.firstName.trim();

  const values: Partial<ContactInfoFormValues> = {
    // The only required fields.
    // On the backend we enforce a required first name on the chip contact info. When the user submits step 2 to
    // create an account, we only ask for email address - so on the backend, we set the first name to the email address.
    // Making first name optional is not a small task at the moment, so to fix the undesired effect of
    // looking like the firstName field is defaulted to the email address, we're setting it to an empty string when
    // firstName and email are the same. A bit of a hack.
    firstName: firstName.toLowerCase() === gql.primaryEmail.toLowerCase() ? '' : firstName,
    primaryEmail: gql.primaryEmail,
  };
  if (gql.primaryPhone) {
    values.primaryPhone = gql.primaryPhone;
  }
  if (gql.lastName) {
    values.lastName = gql.lastName.trim();
  }
  if (gql.secondaryFirstName) {
    values.secondaryFirstName = gql.secondaryFirstName.trim();
  }
  if (gql.secondaryLastName) {
    values.secondaryLastName = gql.secondaryLastName.trim();
  }
  if (gql.secondaryEmail) {
    values.secondaryEmail = gql.secondaryEmail;
  }
  if (gql.secondaryPhone) {
    values.secondaryPhone = gql.secondaryPhone;
  }
  if (gql.line1) {
    values.line1 = gql.line1;
  }
  if (gql.line2) {
    values.line2 = gql.line2;
  }
  if (gql.city) {
    values.city = gql.city;
  }
  if (gql.zipcode) {
    values.zipcode = gql.zipcode;
  }
  if (gql.state) {
    values.state = gql.state;
  }
  return values;
}

const formValuesToGql = (values: ContactInfoFormValues): gqlTypes.ChipContactInfoInput => ({
  ...values,
  country: `US`,
});

const initialFormValues: ContactInfoFormValues = {
  firstName: '',
  lastName: '',
  secondaryFirstName: '',
  secondaryLastName: '',
  primaryEmail: '',
  secondaryEmail: '',
  primaryPhone: '',
  secondaryPhone: '',
  line1: '',
  line2: '',
  city: '',
  zipcode: '',
  state: '',
};

export interface ContactInfoFormProps {
  disabled?: boolean;
  initialValues?: Partial<ContactInfoFormValues>;
  onCancel: () => void;
  onSubmit: (contactInfoInput: gqlTypes.ChipContactInfoInput) => void;
  responseError?: string;
}

export default function ContactInfoForm({
  initialValues: initialValuesFromProps,
  onSubmit,
  onCancel,
  disabled,
  responseError,
}: ContactInfoFormProps) {
  const { currentUserIsReseller } = useContext(RegisterChipContextV2);

  const initialValues: ContactInfoFormValues = {
    ...initialFormValues,
    ...initialValuesFromProps,
  };
  const haveInitialValuesFromProps = Object.values(initialValuesFromProps ?? {}).filter((v) => v).length > 0;
  const [addAnotherContact, setAddAnotherContact] = useState(false);

  const requirePrimaryPhoneNumber = getFiGrowthBook().getFeatureValue(
    'nano-registration-require-primary-phone-number',
    false,
  );
  const validationSchema = yup.object({
    firstName: yup.string().required(`Please enter a first name`),
    lastName: yup.string(),
    secondaryFirstName: yup.string(),
    secondaryLastName: yup.string(),
    primaryEmail: yup
      .string()
      .email(`Please enter a valid email address`)
      .test('sane_email', `Please enter a valid email address`, saneEmail)
      .required(`Please enter an email address`),
    primaryPhone: yup
      .string()
      .test('is_valid_phone', 'Please enter a valid primary phone number', isValidPhoneNumber)
      .when([], {
        is: () => requirePrimaryPhoneNumber,
        then: yup.string().required('Please enter a primary phone number'),
      }),
    line1: yup.string(),
    line2: yup.string(),
    city: yup.string(),
    zipcode: yup.string(),
    state: yup.string(),
  });

  return (
    <div className={styles.contactInfoForm}>
      <Formik
        onSubmit={(values) => {
          onSubmit(formValuesToGql(values));
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, isValid, dirty, submitForm, values, setValues }) => {
          const actionsDisabled = !isValid || disabled || (!dirty && !haveInitialValuesFromProps);

          return (
            <Form next onSubmit={handleSubmit}>
              <div className="form-section">
                <div className="form-section-header-container">
                  <label>{currentUserIsReseller ? `New owner` : `Owner`}</label>
                  {!addAnotherContact && (
                    <Button label="+ Add another contact" onClick={() => setAddAnotherContact(true)} />
                  )}
                </div>
                <div className="form-group">
                  <div className="form-field">
                    <Field type="text" name="firstName" placeholder="First name" />
                  </div>
                  <div className="form-field">
                    <Field type="text" name="lastName" placeholder="Last name" />
                  </div>
                </div>
                <ErrorMessage name="firstName" component="div" className="form-field__error" />
                <ErrorMessage name="lastName" component="div" className="form-field__error" />
                <SingleTextFieldFormGroup name="primaryPhone" type="tel" placeholder="Phone number" />
                {currentUserIsReseller && (
                  <SingleTextFieldFormGroup name="primaryEmail" type="email" placeholder="Email address" />
                )}
              </div>
              {addAnotherContact && (
                <>
                  <div className="form-section secondary-owner-section">
                    <div className="form-section-header-container">
                      <label>Secondary Owner</label>
                      <Button
                        label="Remove"
                        onClick={() => {
                          setAddAnotherContact(false);
                          setValues({ ...values, secondaryFirstName: '', secondaryLastName: '', secondaryPhone: '' });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-field">
                        <Field type="text" name="secondaryFirstName" placeholder="First name" />
                      </div>
                      <div className="form-field">
                        <Field type="text" name="secondaryLastName" placeholder="Last name" />
                      </div>
                    </div>
                    <ErrorMessage name="secondaryFirstName" component="div" className="form-field__error" />
                    <ErrorMessage name="secondaryLastName" component="div" className="form-field__error" />
                    <SingleTextFieldFormGroup name="secondaryPhone" type="tel" placeholder="Phone number" />
                  </div>
                </>
              )}
              <div className="form-section">
                <label>Home address</label>
                <SingleTextFieldFormGroup name="line1" placeholder="Address" />
                <SingleTextFieldFormGroup name="line2" placeholder="Apt/suite/other (optional)" />
                <SingleTextFieldFormGroup name="city" placeholder="City" />
                <div className="form-group">
                  <div className="form-field">
                    <StateSelectField name="state" />
                    <ErrorMessage name={'state'} component="div" className="form-field__error" />
                  </div>
                </div>
                <SingleTextFieldFormGroup name="zipcode" placeholder="Zip" />
              </div>
              <div className="form-section">
                <div className="form-disclaimer">
                  By clicking on the continue button you agree to receiving lost pet related and marketing
                  communications from Fi.
                </div>
                <div className="form-group form-group--action form-group--multistep-action">
                  <Button
                    primary
                    label="Continue"
                    type="submit"
                    disabled={actionsDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                  />
                  <Button
                    label="Go back"
                    type="submit"
                    disabled={disabled}
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel?.();
                    }}
                  />
                </div>
                {responseError && <div className="form-field__error">{responseError}</div>}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
