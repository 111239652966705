import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { ChipSendMessageTarget, ChipSearchResult } from '@barkinglabs/nano-shared';
import ContactForm, { ContactFormValues } from './ContactForm';
import { sendMessage } from '../../../lib/chipsApi';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './ContactModal.module.scss';

export const searchResultToContactModalProps = (searchResult: ChipSearchResult) => ({
  serialNumber: searchResult.chip.serialNumber,
  contactToken: searchResult.contactToken,
});

export interface ContactModalProps {
  trigger: JSX.Element;
  contactTarget: ChipSendMessageTarget;
  serialNumber: string;
  contactToken: string;
}

export default function ContactModal({ trigger, contactTarget, contactToken, serialNumber }: ContactModalProps) {
  const [sent, setSent] = useState(false);
  const ref = useRef<PopupActions>(null);

  const doSubmit = async (values: ContactFormValues) => {
    await sendMessage({
      contactToken,
      serialNumber,
      target: contactTarget,
      fromEmail: values.fromEmail,
      fromName: values.fromName,
      fromPhone: values.fromPhone,
      messageBody: values.messageBody,
    });
    setSent(true);
  };

  return (
    <Popup trigger={trigger} position="center center" modal onClose={() => setSent(false)} ref={ref}>
      <div className={styles.main}>
        {sent ? (
          <div>
            <h4>Your message was sent!</h4>
            <div className="btn-black-outline" onClick={() => ref.current?.close()}>
              Close
            </div>
          </div>
        ) : (
          <ContactForm doSubmit={doSubmit} />
        )}
      </div>
    </Popup>
  );
}
