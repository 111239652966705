import { MutationFunctionOptions } from '@apollo/client';
import classNames from 'classnames';
import { ErrorMessage, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as yup from 'yup';
import Button from '../../../components/Button/Button';
import Form from '../../../components/form/Form';
import FormField from '../../../components/form/FormField';
import StateSelectField from '../../../components/form/StateSelectField';
import { isValidPhoneNumber, saneEmail } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import { useUpdateChipContactInfo } from '../../lib/mutations';

export interface ContactInfo {
  firstName?: string | null;
  lastName?: string | null;
  primaryPhone?: string | null;
  secondaryPhone?: string | null;
  primaryEmail?: string | null;
  secondaryEmail?: string | null;
  website?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
}

const validationSchema = yup.object({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  primaryEmail: yup
    .string()
    .nullable()
    .email(`Please enter a valid primary email address`)
    .test('sane_email', `.con is not valid. Please check the spelling of your email address`, saneEmail)
    .required(`Please enter a primary email address`),
  secondaryEmail: yup
    .string()
    .nullable()
    .email(`Please enter a valid secondary email address`)
    .test('sane_email', `.con is not valid. Please check the spelling of your email address`, saneEmail),
  primaryPhone: yup
    .string()
    .nullable()
    .test('is_valid_phone', `Please enter a valid primary phone number`, isValidPhoneNumber),
  secondaryPhone: yup
    .string()
    .nullable()
    .test('is_valid_phone', `Please enter a valid secondary phone number`, isValidPhoneNumber),
  line1: yup.string().nullable(),
  line2: yup.string().nullable(),
  city: yup.string().nullable(),
  zipcode: yup.string().nullable(),
  state: yup.string().nullable(),
});

interface ContactInfoFormikFormProps {
  initialValues?: ContactInfo;
  responseError?: string;
  disabled?: boolean;
  allowPrimaryEmailEdit?: boolean;
  submitLabel?: string;
  onSubmit: (values: ContactInfo) => void;
}

function ContactInfoFormikForm({
  initialValues,
  responseError,
  disabled,
  allowPrimaryEmailEdit,
  submitLabel = 'Next Step',
  onSubmit,
}: ContactInfoFormikFormProps) {
  return (
    <Formik
      initialValues={
        initialValues
          ? {
              ...initialValues,
              // We default the firstName to email when it wasn't entered by the user, so consider it blank if it's
              // still set to the primary email to encourage the user to enter their first name.
              firstName:
                initialValues.firstName &&
                initialValues.firstName.toLowerCase().trim() === initialValues.primaryEmail?.toLowerCase().trim()
                  ? ''
                  : initialValues.firstName,
            }
          : {
              firstName: '',
              lastName: '',
              primaryPhone: '',
              secondaryPhone: '',
              primaryEmail: '',
              secondaryEmail: '',
              website: '',
              line1: '',
              line2: '',
              city: '',
              state: '',
              zipcode: '',
            }
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, submitForm }) => (
        <Form compact onSubmit={handleSubmit}>
          <div className="form-group">
            <FormField type="text" name="firstName" placeholder="First name" />
            <FormField type="text" name="lastName" placeholder="Last name" />
          </div>

          <div className="form-group">
            <FormField
              type="text"
              name="primaryEmail"
              placeholder="Primary Email Address"
              classNames={classNames({ locked: !allowPrimaryEmailEdit })}
              disabled={!allowPrimaryEmailEdit}
            />
            <FormField type="text" name="secondaryEmail" placeholder="Secondary Email Address" />
          </div>

          <div className="form-group">
            <FormField type="text" name="primaryPhone" placeholder="Primary Phone Number" />
            <FormField type="text" name="secondaryPhone" placeholder="Secondary Phone Number" />
          </div>

          <div className="form-group">
            <FormField type="text" name="line1" placeholder="Address" />
            <FormField type="text" name="line2" placeholder="Apt/Suite/Other" />
          </div>
          <div className="form-group">
            <FormField type="text" name="city" placeholder="City" />
            <div className="form-field">
              <StateSelectField name="state" />
              <ErrorMessage name="state" component="div" className="form-field__error" />
            </div>
            <FormField type="text" name="zipcode" placeholder="Zip Code" />
          </div>
          <div className="form-group form-group--action form-group--multistep-action">
            {responseError && <div className="form-field__error">{responseError}</div>}
            <Button
              primary
              label={submitLabel}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                submitForm();
              }}
              disabled={disabled}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

interface ContactInfoFormProps {
  chipShortId: string;
  petId?: string;
  initialValues?: ContactInfo;
  allowPrimaryEmailEdit?: boolean;
  submitLabel?: string;
  preSubmit?: () => Promise<any>;
  onSuccess?: (response: gqlTypes.updateChipContactInfo) => void;
  mutationOptions?: Pick<MutationFunctionOptions, 'refetchQueries'>;
  disabled?: boolean;
}

export default function ContactInfoForm({
  chipShortId,
  petId,
  initialValues,
  allowPrimaryEmailEdit,
  submitLabel,
  preSubmit,
  onSuccess,
  disabled,
  mutationOptions = {},
}: ContactInfoFormProps) {
  const [updateChipContactInfo, { loading: updateInfoLoading, error: updateInfoError }] = useUpdateChipContactInfo();

  const onSubmit = useCallback(
    (contactInfo: ContactInfo) => {
      if (updateInfoLoading) {
        return;
      }

      const primaryEmail = contactInfo.primaryEmail?.trim() ?? '';
      (preSubmit ? preSubmit() : Promise.resolve())
        .then(() => {
          return updateChipContactInfo({
            ...mutationOptions,
            variables: {
              input: {
                chipId: chipShortId,
                petId,
                contactInfo: {
                  firstName: contactInfo.firstName?.trim() || primaryEmail,
                  lastName: contactInfo.lastName?.trim(),
                  primaryPhone: contactInfo.primaryPhone?.trim(),
                  secondaryPhone: contactInfo.secondaryPhone?.trim(),
                  primaryEmail: primaryEmail ?? '',
                  secondaryEmail: contactInfo.secondaryEmail?.trim(),
                  website: contactInfo.website?.trim(),
                  line1: contactInfo.line1?.trim(),
                  line2: contactInfo.line2?.trim(),
                  city: contactInfo.city?.trim(),
                  state: contactInfo.state?.trim(),
                  zipcode: contactInfo.zipcode?.trim(),
                  country: 'US',
                },
              },
            },
          });
        })
        .then((response) => {
          const updateInfoResponse = response.data;
          if (updateInfoResponse && onSuccess) {
            onSuccess(updateInfoResponse);
          }
        })
        .catch(() => {
          // Error is already displayed using the error returned by the hook, but we need to handle rejection here
        });
    },
    [preSubmit, onSuccess, updateChipContactInfo, updateInfoLoading, chipShortId, petId, mutationOptions],
  );

  return (
    <ContactInfoFormikForm
      initialValues={initialValues}
      submitLabel={submitLabel}
      onSubmit={onSubmit}
      responseError={updateInfoError?.message}
      disabled={disabled || updateInfoLoading}
      allowPrimaryEmailEdit={allowPrimaryEmailEdit}
    />
  );
}
