import React from 'react';
import { ChipSearchResult } from '@barkinglabs/nano-shared';

import PetCard from './components/PetCard';
import VetCard from './components/VetCard';
import ContactHistoryCard from './components/ContactHistoryCard';

import styles from './SearchResult.module.scss';

interface SearchResultsProps {
  result: ChipSearchResult;
}

export default function SearchResult({ result }: SearchResultsProps) {
  const historyEntries = result.contactHistory.slice(1);

  return (
    <div className={styles.main}>
      <PetCard pet={result.pet} searchResult={result} />
      {historyEntries.map((historyEntry, i) => (
        <ContactHistoryCard searchResult={result} historyEntry={historyEntry} key={i} />
      ))}
      {result.reseller && <VetCard searchResult={result} reseller={result.reseller} />}
      <div className={styles.contact}>
        Please contact Fi at <a href="tel:18556266817">1-855-626-6817</a> from 10 AM to 8 PM EST if you are experiencing
        any issues.
      </div>
    </div>
  );
}
