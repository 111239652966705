import React from 'react';
import { Field, ErrorMessage } from 'formik';
import classnames from 'classnames';

export interface FormFieldProps {
  name: string;
  type?: string;
  component?: string;
  inputMode?: string;
  placeholder: string;
  autoComplete?: string;
  min?: string;
  max?: string;
  maxLength?: string;
  classNames?: string[] | string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ignore1Password?: boolean;
}

export default function FormField({
  name,
  type,
  component,
  inputMode,
  placeholder,
  autoComplete,
  min,
  max,
  maxLength,
  classNames,
  disabled,
  ignore1Password,
}: FormFieldProps) {
  return (
    <div className="form-field">
      <Field
        type={type ?? 'text'}
        component={component}
        name={name}
        inputMode={inputMode}
        placeholder={placeholder}
        autoComplete={autoComplete}
        min={min}
        max={max}
        maxLength={maxLength}
        onWheel={(event: any) => {
          event.target.blur();
        }} /* Disable scrolling */
        className={classnames(classNames)}
        disabled={disabled}
        data-1p-ignore={ignore1Password}
      />
      <ErrorMessage name={name} component="div" className="form-field__error" />
    </div>
  );
}
