import { gql } from '@apollo/client';
import phone from 'phone';
import validator from 'validator';
import { TestContext } from 'yup';
import { isPhoneNumber } from 'class-validator';

export const isValidPhoneNumber = (value: string | null | undefined, testContext: TestContext) => {
  // If the field is nullable and the value is null or undefined, we consider it valid
  if (testContext.schema.nullable && (value === null || value === undefined)) {
    return true;
  }

  if (!value) {
    return false;
  }
  return phone(value, { validateMobilePrefix: false }).isValid;
};

export function isValidUSPhoneNumber(value: string | null | undefined, testContext: TestContext) {
  // If the field is nullable and the value is null or undefined, we consider it valid
  if (testContext.schema.nullable && (value === null || value === undefined)) {
    return true;
  }

  if (!value) {
    return false;
  }
  return isPhoneNumber(value, 'US') || isPhoneNumber(value, 'PR') || isPhoneNumber(value, 'VI');
}

const KG_TO_LBS = 2.20462;

export const kgToLbs = (input: number) => input * KG_TO_LBS;

export const lbsToKg = (input: number) => input / KG_TO_LBS;

export const saneEmail = (email: string | null | undefined) => !email || !email.endsWith('.con');

export const isOptionalUrl = (url: string | null | undefined) => (url ? validator.isURL(url) : true);

export const userChipResellerQuery = gql`
  query userChipReseller {
    currentUser {
      chipReseller {
        id
        status
      }
    }
  }
`;
