import React, { useState } from 'react';
import FormField from './FormField';
import eyeVisibleIcon from '../../assets/images/eye-visible.svg';
import eyeHiddenIcon from '../../assets/images/eye-hidden.svg';
import styles from './SingleTextFieldFormGroup.module.scss';

export interface SingleTextFieldFormGroupProps {
  name: string;
  type?: string;
  placeholder: string;
  autoComplete?: string;
  min?: string;
}

export default function SingleTextFieldFormGroup({
  name,
  type,
  placeholder,
  autoComplete,
  min,
}: SingleTextFieldFormGroupProps) {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`form-group ${styles.formGroupContainer}`}>
      <FormField
        type={isPassword ? (showPassword ? 'text' : 'password') : type ?? 'text'}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        min={min}
        classNames={isPassword ? 'password-field' : undefined}
        ignore1Password={isPassword}
      />
      {isPassword && (
        <button type="button" onClick={togglePasswordVisibility} className={styles.toggleButton}>
          <img
            src={showPassword ? eyeHiddenIcon : eyeVisibleIcon}
            alt={showPassword ? 'Hide password' : 'Show password'}
            className={styles.toggleIcon}
          />
        </button>
      )}
    </div>
  );
}
